import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Header from './Components/Layout/Header';
import Location from './Pages/Location';
import Simulate from './Pages/Simulate';

import { DrawerContextProvider } from './store/DrawerContext';
import { ModalContextProvider } from './store/ModalContext';
import { UserContextProvider } from './store/UserContext';
import DetailsModal from './Components/DetailsModal';
import DetailsDrawer from './Components/DetailsDrawer';
import Authorize from './Pages/Authorize';


function App() {

  return (
    <div className="App">
      <UserContextProvider>
				<DrawerContextProvider>
					<ModalContextProvider>
						<BrowserRouter>
							<Header /> 
							<main className="main">						
								<Routes>
									<Route path="/:trackingId/:lat/:lng" exact index element={<Location/>} />
									<Route path="/simulate/:lat/:lng" exact index element={<Simulate/>} />
									<Route path="/authorize/:userId/contact/:phone" exact index element={<Authorize/>} />
									<Route path="/overview" exact element={<Location/>} />
									<Route path="/" exact index element={<Navigate to="/location"/>} />
								</Routes>
							</main>
							<DetailsModal />
							<DetailsDrawer />
						</BrowserRouter>
					</ModalContextProvider>
				</DrawerContextProvider>
			</UserContextProvider>
    </div>
  );
}

export default App;
