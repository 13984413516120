import React from 'react';
import { initializeApp } from "firebase/app";
import { getStorage, uploadBytes, ref, uploadString } from "firebase/storage";
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// Initialize Firebase
const app = initializeApp ({
  apiKey: "AIzaSyBDxzT4R7OVIu-rom3Pq1KNjTEOBLr2758",
  authDomain: "deliva.firebaseapp.com",
  databaseURL: "https://deliva.firebaseio.com",
  projectId: "deliva",
  storageBucket: "deliva.appspot.com",
  messagingSenderId: "353810213624",
  appId: "1:353810213624:web:c17dff054c89da01cf690d",
  measurementId: "G-BK3RY4LYC1"
});
 
// Firebase storage reference
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { database, auth, firestore, uploadBytes, ref, uploadString };

export default storage;
