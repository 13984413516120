import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { signInAnonymously, signOut, onIdTokenChanged } from 'firebase/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

import authorize from "../Assets/Images/authorize.svg"
import done from "../Assets/Images/done.svg"
import close from "../Assets/Images/close.svg"
import { Button } from '@material-ui/core';
import ModalContext from '../store/ModalContext';
import { auth } from "../store/Firebase.js"

const Authorize = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const { userId, phone } = useParams();
  const [responseAuthorize, setResponseAuthorize]= useState("main");

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");

  const modalCtx = useContext(ModalContext);

  const handleModal = (modal, data) => {    
    modalCtx.setDetails(modal, data);
    modalCtx.openModal();
  }

  useEffect(() => {
    getAuthorizeDetails();

    

    // fetch(process.env.REACT_APP_API_URI + apiCall, {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: jsonBody
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   if(data.status == "success") {
    //     localStorage.removeItem("assessmentAnswers");
    //     localStorage.removeItem("additionalAnswers");
    //     navigate("/results/" + id, { replace: true });
    //   } else {
    //     // setOpenSnackbar(true);
    //   }
    // });
		
		// console.log(trackingId);
		// get(query(ref(database, '/tracking/' + trackingId), limitToLast(1)))
		// .then((snapshot) => {
		// 	if (snapshot.exists()) {
		// 		snapshot.forEach((item) => {
		// 			console.log(item.val().name);
		// 			userCtx.setName(item.val().name);
		// 			userCtx.setPhone(item.val().phone);
		// 		});
		// 	}
		// });

		// var userQuery = trackingRef.orderByChild('trackingId').equalTo(trackingId);
		// userQuery.once("value", function(snapshot) {
		// 	snapshot.forEach(function(child) {
		// 		console.log(child.key, child.val());
		// 	});
		// });

	}, []);

  const getAuthorizeDetails = async () => {

    let accessToken;
    await signInAnonymously(auth)
    .then((data) => {      
      console.log(data);
      accessToken = data.user.accessToken;
      setToken(accessToken);
    })
    .catch((error) => {
      console.log(error, "this is error");
    });

    fetch('https://api.delivait.com/v3/user/panic/' + userId + '/contacts/' + phone, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        setName(data.data);
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const authorizeContact = () => {
  

    fetch('https://api.delivait.com/v3/user/panic/' + userId + '/contact/' + phone + '/authorize', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
      body: JSON.stringify({phone: phone})
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        setResponseAuthorize("confirm");
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const declineContact = () => {
    

    fetch('https://api.delivait.com/v3/user/panic/' + userId + '/contact/' + phone + '/decline', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
      body: JSON.stringify({phone: phone})
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        setResponseAuthorize("decline");
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  if(isLoading) {
    return (
      <div className='authorize'><CircularProgress /></div>
    )
  }

  return (
    <div className='authorize'>

      {responseAuthorize == "main" ? <><div className='card'>
        <img src={authorize} />
        <p><strong>{name}</strong> has asked you to become an emergency contact for his panic button.</p>
        <p style={{ fontSize: "20px" }}>Do you accept this role?</p>
        <div className='buttons'>
          <Button variant='outlined' style={{ borderColor: "#CD4A4A", color: "#CD4A4A" }} fullWidth onClick={() => declineContact()}>Decline</Button>
          <Button variant='contained' fullWidth style={{ backgroundColor: "#CD4A4A", color: "white" }} onClick={() => authorizeContact()}>Accept</Button>
        </div>
        <p style={{ fontSize: "12px" }}>By accepting you'll receive an email and SMS with live tracking location of {name} when their panic button is triggered.</p>
      </div></> : ""}

      {responseAuthorize == "confirm" ? <>
      <div className='card'>
      <div className='confirm-authorize-modal'>
        <img src={done}></img>
        <span className='title'>Thank You!</span>
        <p>You have now been authorized as an emergency contact for {name}.</p>
        {/* <Button variant='contained' style={{ backgroundColor: "#CD4A4A", color: "white", marginTop:"10px" }} >Done</Button> */}
      </div> </div></> : ""}

      {responseAuthorize == "decline" ? <>
      <div className='card'>
      <div className='confirm-authorize-modal'>
      <img src={done}></img>
      <span className='title'>Declined!</span>
      <p>You have declined as an emergency contact for {name} </p>
      {/* <Button variant='contained' style={{backgroundColor:"#CD4A4A", color:"white", marginTop:"10px"}} >Done</Button> */}
    </div></div></> : ""}


      
    </div>
  )
}

export default Authorize