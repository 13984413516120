import React, { useContext } from 'react'
import done from "../../Assets/Images/done.svg"
import close from "../../Assets/Images/close.svg"
import { Button } from '@material-ui/core'
import ModalContext from '../../store/ModalContext'

const AuthorizeConfirmModal = () => {
  const modalCtx = useContext(ModalContext);
  console.log(modalCtx);
  const name = modalCtx?.details;

  const closeHandle = () => {
    modalCtx.closeModal();
  }

  return (
    <div className='confirm-authorize-modal'>
      <div className='close-div'>
          <img src={close} onClick={() => closeHandle()}></img>
      </div>
      <img src={done}></img>
      <span className='title'>Thank You!</span>
      <p>You have now been authorized as an emergency contact for {name}.</p>
      <Button variant='contained' style={{backgroundColor:"#CD4A4A", color:"white"}} onClick={() => closeHandle()}>Done</Button>
    </div>
  )
}

export default AuthorizeConfirmModal