// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const app = initializeApp({
  apiKey: "AIzaSyBDxzT4R7OVIu-rom3Pq1KNjTEOBLr2758",
  authDomain: "deliva.firebaseapp.com",
  databaseURL: "https://deliva.firebaseio.com",
  projectId: "deliva",
  storageBucket: "deliva.appspot.com",
  messagingSenderId: "353810213624",
  appId: "1:353810213624:web:c17dff054c89da01cf690d",
  measurementId: "G-BK3RY4LYC1"
});

export const auth = getAuth();
export default app