import React, { createContext, useState } from 'react';

const UserContext = createContext({
  name: '',
  phone: '',
  setName: (setName) => {},
  setPhone: (setPhone) => {}
});

export function UserContextProvider(props) {

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  function setNameHandler(name) {
    setName(name);
  }

  function setPhoneHandler(phone) {
    setPhone(phone);
  }

  const context = {
    name: name,
    phone: phone,
    setName: setNameHandler,
    setPhone: setPhoneHandler
  };

  return <UserContext.Provider value={context}>
    {props.children}
  </UserContext.Provider>
}

export default UserContext;