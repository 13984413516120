import { useState, useContext, useEffect } from 'react';
import { Modal, Box } from '@material-ui/core';

import ModalContext from '../store/ModalContext.js';
import AuthorizeConfirmModal from './Modals/AuthorizeConfirmModal.jsx';
import DeclineConfirmModal from './Modals/DeclineConfirmModal.jsx';



// const DetailsModal = ({ lightboxModalProps }) => {
const DetailsModal = () => {
  // const { setToUpdate, goals, currentGoalID, toEdit, openModal, handleCloseModal, closeGoalMenu, goalToDelete, goalAction } = lightboxModalProps;

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '70vw',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
    maxHeight: '90vh',
  };

  const currentModal = () => {
    switch (modalCtx.modal) {

      //devotional
      // case "createDevotional": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CreateDevotional /></Box></Modal>;
      case "confirm-authorize": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AuthorizeConfirmModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "decline-authorize": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeclineConfirmModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "confirm-delete-day": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteDayModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "confirm-delete-tag": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTagModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "confirm-delete-topic": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTopicModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "confirm-delete-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   //file upload
    //   case "upload-image": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadImageModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "upload-pdf": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadPdfModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   //other
    //   case "edit-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "edit-topics": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTopicsModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "edit-tags": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTagsModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   //course
    //   case "confirm-delete-course": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteCourseModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "edit-course-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditCourseAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "edit-course-topics": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditCourseTopicsModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "edit-course-tags": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditCourseTagsModal modalDetails={modalCtx.details} /></Box></Modal>;

    //   //administrator
    //   case "edit-permissions": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditPermissionsModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   //books
    //   case "confirm-delete-book": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteBookModal modalDetails={modalCtx.details} /></Box></Modal>;

    //   //blogs
    //   case "confirm-publish-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><PublishBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "confirm-delete-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "edit-blog-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "edit-blog-topics": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogTopicsModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "edit-blog-tags": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogTagsModal modalDetails={modalCtx.details} /></Box></Modal>;
    //   case "blog-comment-approve": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CommentsApprove modalDetails={modalCtx.details} /></Box></Modal>;
    //   
    default: return null;
    }
  }

  return (
    <div>{currentModal()}</div>
  );

}

export default DetailsModal;
