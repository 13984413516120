import React, { useContext } from 'react';
import Logo from '../../Assets/Images/logo.png';
import Sidebar from '../../Assets/Images/sidebar.svg';
import Profile from '../../Assets/Images/profile.png';
import warning from '../../Assets/Images/warning.svg';
import '../../App.scss'

import UserContext from '../../store/UserContext';

const Header =() => {
	
	const userCtx = useContext(UserContext);
  
  // const queryParams = new URLSearchParams(window.location.search);
  // const preview = queryParams.get('preview');
  // console.log(preview);
  console.log(window.location.href.indexOf('preview'));
  // console.log(window.location.href.indexOf("franky") != -1)

  const showHeaderDetails = () => {
    if(window.location.href.indexOf('simulate') != -1) {
      return "Simulation";
    } else if(window.location.href.indexOf('simulate') != -1) {
      return "Trigged by " + userCtx.name;
    } else {
      return null;
    }
  }
  
  return (
    <>
      <header>
        {/* <img src={Sidebar}  className="sidebar" /> */}
        <img src={Logo} className="logo" />
        <div className="profile">{ showHeaderDetails() }</div>
      </header>
      {
        window.location.href.indexOf('simulate') != -1 ? <>
          <div className='warning-simulate'>
            <img src={warning}></img>
            <span>Warning! You are in simulated mode and we are displaying your current location based on the test that your doing while setting up panic button.</span>
          </div>
        </> : <></>
      }
    </>
  )
  
}

export default Header;