import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ref, query, get, equalTo, on, onValue, orderByChild, limitToLast, onChildAdded } from "firebase/database";
import { signInAnonymously } from 'firebase/auth';
import GoogleMapReact from 'google-map-react';

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Place } from "@material-ui/icons";

import { database, auth } from "../Components/FirebaseConfig";
import UserContext from '../store/UserContext';
import '../App.scss';

import logo from '../Assets/Images/profile.png'
import fire from '../Assets/Images/fire.svg'
import ambulance from '../Assets/Images/ambulance.svg'
import police from '../Assets/Images/police.svg'
import pin from '../Assets/Images/pin.svg';


const LocationPin = ({ text }) => {

  const [pulsate, setPulsate] = useState(false);

  return (
    <div className="pin">
      {/* <div className="rectangle">
        <div className="location-icon"><img src={pin}/> Montclair</div>
        <p>{text}</p>
        <div className='triangle'></div>
      </div>		 */}
      {/* <div className="bigger-circle" style={pulsate ? { height: '10px', width: '10px' } : null}>
        <div className="smaller-circle">
          {''}
          <div className="circle">{''}</div>
        </div>
      </div> */}
      <div class="circle" style={{ animationDelay: "-3s" }}></div>
      <div class="circle" style={{ animationDelay: "-2s" }}></div>
      <div class="circle" style={{ animationDelay: "-1s" }}></div>
      <div class="circle" style={{ animationDelay: " 0s" }}></div>
    </div>
  )
}
const Location = () => {

  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  const { trackingId, lat, lng } = useParams();

  const [name, setName] = useState("");
  const [footerHeight, setFooterHeight] = useState(true);

  const [center, setCenter] = useState({
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  });


  useEffect(() => {
    const dbRef = ref(database, '/tracking/' + trackingId);
    const unsubscribe = onChildAdded(dbRef, (snapshot) => {
      const newItem = snapshot.val();
      console.log("Latest ", newItem.lat, newItem.lng);

      if (newItem.lat) {
        setCenter({
          lat: newItem.lat,
          lng: newItem.lng
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [trackingId]);

  useEffect(() => {

    console.log(trackingId);
    get(query(ref(database, '/tracking/' + trackingId), limitToLast(1)))
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            console.log(item.val().name);
            userCtx.setName(item.val().name);
            userCtx.setPhone(item.val().phone);
          });
        }
      });
    // var userQuery = trackingRef.orderByChild('trackingId').equalTo(trackingId);
    // userQuery.once("value", function(snapshot) {
    // 	snapshot.forEach(function(child) {
    // 		console.log(child.key, child.val());
    // 	});
    // });

  }, [trackingId, userCtx]);

  const footerHandler = (e) => {
    e.preventDefault();
    setFooterHeight(!footerHeight);
  }

  return (
    <>
      <div className="location">
        <div className="map">
          {center.lat ? <Map center={center} zoom={15} /> : <div>Loading</div>}
        </div>

        <div className="footer-container" style={footerHeight ? { height: "52px" } :  { height: "250px" }} >
          <div className="location-footer">
            <button className="show-footer" onClick={footerHandler}>{!footerHeight ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}</button>
            {/* <div className="footer-heading">
              <span className="footer-title">Emergency Contacts </span>
              
            </div> */}
            <div className="details-row">
              <div className="single-detail panic-name">
                <div className="detail-title-row">
                  <span className="title">{userCtx.name}</span>
                </div>
                <span className="phone-number">
                  <a href={`tel:${userCtx.phone}`}>{userCtx.phone}</a>
                </span>
              </div>

              <div className="single-detail">
                <div className="detail-title-row">
                  <img src={police} alt="test" /><span class="service-title">Police</span>
                </div>
                <span className="phone-number">
                  <a href="tel:9623432186">9623432186</a>
                </span>
              </div>

              <div className="single-detail">
                <div className="detail-title-row">
                  <img src={ambulance} alt="test" /><span class="service-title">Ambulance</span>
                </div>
                <span className="phone-number">
                  <a href="tel:110177">110177</a>
                </span>
              </div>

              <div className="single-detail">
                <div className="detail-title-row">
                  <img src={fire} alt="test" /><span class="service-title">Fire Department</span>
                </div>
                <span className="phone-number">
                  <a href="tel:11022">11022</a>
                </span>
              </div>



            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Location;


const Map = ({ center, zoom }) => {
  console.log(center);

  return (
    <div className="map-item" >
      <GoogleMapReact
        options={getMapOptions}
        bootstrapURLKeys={{ key: 'AIzaSyBDxzT4R7OVIu-rom3Pq1KNjTEOBLr2758' }} // replace with your actual API key
        center={center}
        defaultZoom={zoom}
        gestureHandling='none'
      >

        <LocationPin
          lat={center.lat}
          lng={center.lng}
          text="1600 Amphitheatre Parkway, Mountain View, california."

        />
      </GoogleMapReact>
    </div>
  );
};

const getMapOptions = (maps) => {

  return {

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.HYBRID,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.TOP_LEFT,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID
      ]
    },
  };
}