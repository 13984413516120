
import React, { useState, useContext, useEffect, Component  } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@material-ui/core'
import DrawerContext from '../store/DrawerContext';

const noMenuRoutes = ["/login", "/register"];

function DetailsDrawer(props) {

	const drawerCtx = useContext(DrawerContext);
	const { pathname } = useLocation();

	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedTab, setSelectedTab] = React.useState(0);
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	function closeDrawer() {
		drawerCtx.closeDrawer();
	}

	if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

	const currentDrawer = () => {
		switch(drawerCtx.drawer) {
			
			// // Users
			// case "user-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><UserDrawer userDetails={drawerCtx.details} /></Drawer>;
			// case "create-user": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateUserDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// //devotional
			// case "create-devotional": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateDevotionalDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "create-day": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateDayDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "edit-day": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDayDrawer /></Drawer>;
			// case "edit-devotional": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDevotionalDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "edit-devotional-author": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDevotionalAuthorDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "edit-devotional-tags": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDevotionalTagsDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "edit-devotional-topics": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDevotionalTopicsDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// //courses
			// case "create-course": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateCourseDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "create-course-episode": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateCourseEpisodeDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "edit-course": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditCourseDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "edit-episode": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditEpisodeDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// //books
			// //blogs
			// case "create-book": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateBookDrawer /></Drawer>;
			// case "edit-book": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditBlogDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			

			// //blogs
			// case "create-blog": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateBlogDrawer /></Drawer>;
			// case "edit-blog": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditBlogDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// //other
			// case "create-network": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateNetworkDrawer /></Drawer>;
			// case "create-topic": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateTopicDrawer /></Drawer>;
			// case "edit-topic": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditTopicDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "create-edit-review": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateEditReviewDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// // case "edit-review": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditTopicDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "create-tag": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateTagDrawer /></Drawer>;
			// case "edit-tag": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditTagDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "create-author": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateAuthorDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "edit-author": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditAuthorDrawer drawerDetails={drawerCtx.details} /></Drawer>;

			default:  return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>;
		}
	}
		return (
			<div>{ currentDrawer() }</div>
		);
	

}
export default DetailsDrawer;
